import { useFormField, useBooleanFormField, useNumberFormField } from '@kaliber/forms'
import { FormFieldError } from '/features/pageOnly/JobApplication/FormFieldError'
import { Icon } from '/features/buildingBlocks/Icon'
import { SelectFormField } from '/features/pageOnly/JobApplication/Select'
import { useTranslate } from '/machinery/I18n'

import styles from './FormField.css'

import warningIcon from '/images/icons/warning-solid.raw.svg'
import crossIcon from '/images/icons/mark-bold.raw.svg'

export const formFieldRenderers = {
  TEXT: FormFieldTextArea,
  MULTIPLE_CHOICE: FormFieldRadioGroup,
  RATING_SCALE: FormFieldSelect,
  NUMERIC: FormFieldNumber,
}

export function FormFieldText({ field, type, label, placeholder = undefined, required = undefined, readOnly = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      <div className={styles.textField}>
        <label id={`${name}-label`} className={styles.label}>{label}{required && '*'}</label>
        <input
          id={name}
          aria-labelledby={`${name}-label`}
          className={cx(styles.inputTextField, showError && styles.errorField)}
          {...eventHandlers}
          {...{ type, name, value, required, placeholder, readOnly }}
        />
      </div>
    </FormFieldBase>
  )
}

export function FormFieldDropdown({ field, type, options, label = undefined, required = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state
  const { __ } = useTranslate()

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      <div className={styles.textField}>
        <label id={`${name}-label`} className={styles.label}>{label}{required && '*'}</label>
        <select
          id={name}
          aria-labelledby={`${name}-label`}
          className={cx(styles.inputDropdown, showError && styles.errorField)}
          {...eventHandlers}
          {...{ type, name, value, required }}
        >
          <option value="" disabled hidden>{__`make-a-choice`}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </FormFieldBase>
  )
}

export function FormFieldCheckbox({ field, children, hasMultipleLines = undefined, required = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      <div className={cx(styles.checkbox, hasMultipleLines && styles.checkboxHasMultipleLines)}>
        <input
          id={name}
          aria-labelledby={`${name}-label`}
          className={cx(styles.inputFieldCheckbox, value && styles.checked)}
          type="checkbox"
          checked={value}
          {...{ value, name, required }}
          {...eventHandlers}
        />
        <label id={`${name}-label`} htmlFor={name} className={styles.labelCheckbox}>
          {children}{required && '*'}
        </label>
      </div>
    </FormFieldBase>
  )
}

export function FormFieldFile({ field, placeholder, label, required = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      <label id={`${name}-label`} className={styles.label}>{label}{required && '*'}</label>
      <div className={styles.fileField}>
        {value ? (
          <button aria-labelledby={`${name}-label`} type='button' onClick={_ => onChange(null)} data-x='remove-file' className={styles.button}>
            <span>{value.name}{required && '*'}</span>
            <span className={styles.icon}><Icon icon={crossIcon} /></span>
          </button>
        ) : (
          <div className={styles.buttonContainer}>
            <input
              id={name}
              type='file'
              accept=".pdf,.docx"
              className={styles.fileInput}
              {...{ name, required }}
              {...eventHandlers}
              onChange={e => {
                onChange(e.currentTarget.files[0])
                eventHandlers.onBlur(e)
              }}
            />
            <label data-x='select-file' className={styles.button} htmlFor={name}>
              <span>{placeholder}{required && '*'}</span>
            </label>
          </div>
        )}
      </div>
    </FormFieldBase>
  )
}

export function FormFieldTextArea({ field, label = undefined, required = undefined, layoutClassName = undefined, description = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      {label && (
        <div className={styles.textAreaHeader}>
          <label id={`${name}-label`} htmlFor={name} className={styles.label}>{`${label}${required ? '*' : ''}`}</label>
          {description && <p className={styles.textAreaDescription}>{description}</p>}
        </div>
      )}

      <div className={styles.textAreaField}>
        <textarea
          id={name}
          aria-labelledby={`${name}-label`}
          className={cx(styles.inputFieldTextArea, showError && styles.errorField)}
          {...eventHandlers}
          {...{ name, value, required }}
        />
      </div>
    </FormFieldBase>
  )
}

export function FormFieldSelect({ field, label = undefined, placeholder, options = [], required = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state
  const { label: currentOptionLabel } = options.find(x => numberToString(x.value) === value) ?? ''

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      {label && <label id={`${name}-label`} htmlFor={name} className={styles.label}>{label}</label>}

      <div className={styles.selectField}>
        <SelectFormField
          ariaLabeledby={`${name}-label`}
          layoutClassName={styles.baseLayout}
          label={value ? currentOptionLabel : placeholder + required && '*'}
          {...{ name, value, eventHandlers, placeholder, options }}
        />
      </div>
    </FormFieldBase>
  )
}

export function FormFieldRadioGroup({ field, options, required, label = undefined, children = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state
  const labelOrChildren = label || children

  return (
    <FormFieldBase error={showError && error}>
      {labelOrChildren && <label id={`${name}-label`} className={styles.label}>{labelOrChildren}{required && '*'}</label>}

      <div className={styles.radioGroupOptions}>
        {options.map(option => {
          const id = `${name}_${option.label}`
          return (
            <div key={id} className={styles.radioItem}>
              <input
                type='radio'
                key={id}
                checked={value === option.value.toString()}
                value={option.value}
                aria-labelledby={`${name}-label`}
                className={cx(styles.inputRadio, value === option.value.toString() && styles.checked)}
                {...{ name, id }}
                {...eventHandlers}
              />
              <label className={styles.labelRadio} htmlFor={id}>
                {option.label}
              </label>
            </div>
          )
        })}
      </div>
    </FormFieldBase>
  )
}

export function FormFieldNumber({ field, type, label, required = undefined, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useNumberFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase error={showError && error} className={layoutClassName}>
      {label && <label id={`${name}-label`} htmlFor={name} className={styles.label}>{label}{required && '*'}</label>}

      <div className={styles.textField}>
        <input
          id={name}
          aria-labelledby={`${name}-label`}
          className={cx(styles.inputFieldNumber, showError && styles.errorField)}
          inputMode='numeric'
          {...eventHandlers}
          {...{ type, name, value, required }}
        />
      </div>
    </FormFieldBase>
  )
}

function FormFieldBase({ children, error = undefined, className = undefined }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
      {error && (
        <p className={styles.formValidationMessage}>
          <Icon icon={warningIcon} layoutClassName={styles.iconLayout} />
          <FormFieldError {...{ error }} />
        </p>
      )}
    </div>
  )
}

function numberToString(value) {
  return typeof value === 'number' ? value.toString() : value
}
