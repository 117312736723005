import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Select.css'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'

export function SelectFormField({ name, value, label, ariaLabeledby, eventHandlers, placeholder, options = [], layoutClassName = undefined }) {
  return <SelectBase {...{ name, value, label, ariaLabeledby, eventHandlers, placeholder, options }} className={layoutClassName} />
}

export function SelectJobListFilter({ name, value, placeholder, onChange, dataX, options = [] }) {
  const { label = placeholder } = options.find(x => x.value === value) ?? {}

  return (
    <div className={styles.componentJobListFilter}>
      <SelectBase {...{ name, value, label, onChange, placeholder, options, dataX }} className={styles.baseLayout} />
    </div>
  )
}

function SelectBase({
  name,
  value,
  label,
  placeholder,
  options,
  ariaLabeledby = undefined,
  dataX = undefined,
  onChange = undefined,
  eventHandlers = undefined,
  className
}) {
  const optionsWithPlaceholder = [ { value: '', label: placeholder }, ...options]

  return (
    <>
      <select id={name} data-x={dataX} className={cx(styles.select, className)} {...{ name, value, onChange, ariaLabeledby }} {...eventHandlers}>
        {optionsWithPlaceholder.map(x => (
          <option key={x.value} value={x.value ?? ''}>{x.label}</option>
        ))}
      </select>
      <p>{label}</p>
      <Icon icon={iconChevronDown} layoutClassName={styles.iconLayout} />
    </>
  )
}
