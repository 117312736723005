import { error } from '@kaliber/forms/validation'

export function maxFileSize(maxSize) {
  return x => x && (x.size > maxSize) && error('maxFileSize', maxSize)
}

export function minFileSize(minSize) {
  return x => x && (x.size <= minSize) && error('minFileSize')
}

export function fileExtension(extensions) {
  return x => {
    if (!x) return

    const [ext] = x.name.split('.').reverse()
    return (!ext || !extensions.some(x => ext.toLowerCase() === x)) &&
      error('fileExtension', extensions)
  }
}

export const number = x => x && Number(x) !== x && error('number')

const atsPhoneRegex = /^[0-9\+ \(\)]*$/
export const atsPhoneNumber = x => x && !atsPhoneRegex.test(x) && error('atsPhoneNumber')

export function checked(x) {
  return !x && error('checked')
}

export function whitespace(x) {
  return x && /\s/g.test(x) && error('whitespace')
}

export function noKLM(x) {
  return x && isKLMEmail(x) && error('internalEmail')
}

export function noKLMRefer(x) {
  return x && isKLMEmail(x) && error('internalEmailRefer')
}

function isKLMEmail(x) {
  return /@klm\.com|@klmcargo\.com|@td\.klm\.com|@airfranceklm\.com/gi.test(x)
}
