import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './BoardingPass.css'

import klmLogo from '/images/logos/klm-logo.raw.svg'

export function BoardingPass({ values, recruiter, hiringManager, jobArea, id, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { givenName, surname, countryOfResidence: country } = values ?? {}
  const team = jobArea?.name ?? null
  const date = getFormattedDate()
  const departure = getFormattedDestination({ country })
  const passenger = getFormattedPassenger({ givenName, surname })
  const flight = `KL-${id}`

  return (
    <div className={cx(styles.component, layoutClassName)}>

      <div className={styles.boardingPassLogo}>
        <Icon layoutClassName={styles.iconLayout} icon={klmLogo} />
      </div>

      <div className={styles.boardingPassLeft}>
        <Row>
          {departure && <TitleAndValue title={__`boarding-pass`} value={departure} />}
          {flight && <TitleAndValue title={__`your-flight`} value={flight} />}
          {date && <TitleAndValue title={__`date`} value={date} />}
        </Row>

        <Row>
          {passenger && <TitleAndValueBig title={__`passenger`} value={passenger} />}
        </Row>

        <Row>
          {team && <TitleAndValueBig title={__`job-area`} value={team} />}
          {country && <TitleAndValueBig title={__`departure-airport`} value={country} />}
          <BoardingPriority />
        </Row>
      </div>

      <div className={styles.boardingPassDivider}>
        <div className={styles.boardingPassDividerLine} />
      </div>

      <div className={styles.boardingPassRight}>
        {hiringManager?.name && <TitleAndValueWithoutCapitals title={__`boarding-hiring-manager`} value={hiringManager.name} /> }
        {recruiter?.name && <TitleAndValueWithoutCapitals title={__`boarding-recruiter`} value={recruiter.name} /> }
      </div>

    </div>
  )
}

function Row({ children }) {
  return (
    <div className={styles.componentRow}>
      {children}
    </div>
  )
}

function TitleAndValue({ title, value, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentTitleAndValue, layoutClassName)}>
      <span className={styles.itemTitle}>{title}</span>
      <span className={styles.itemValue}>{value}</span>
    </div>
  )
}

function TitleAndValueBig({ title, value, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentTitleAndValueBig, layoutClassName)}>
      <span className={styles.itemTitle}>{title}</span>
      <span className={styles.itemValueBig}>{value}</span>
    </div>
  )
}

function TitleAndValueWithoutCapitals({ title, value, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentTitleAndValueWithoutCapitals, layoutClassName)}>
      <span className={styles.itemTitle}>{title}</span>
      <span className={styles.itemValueWithoutCapitals}>{value}</span>
    </div>
  )
}

function BoardingPriority() {
  return (
    <div className={styles.componentBoardingPriority}>
      <span className={styles.itemTitle}>Boarding priority</span>
      <div className={styles.priorityContainer}>
        <span className={styles.priorityItem}>1</span>
        <span className={styles.priorityItem}>2</span>
        <span className={cx(styles.priorityItem, styles.active)}>3</span>
        <span className={styles.priorityItem}>4</span>
      </div>
    </div>
  )
}

function getFormattedDate() {
  const date = new Date()
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })

  return `${day} ${month}`
}

function getFormattedDestination({ country }) {
  if (!country) return
  const depatureFrom = country.substring(0, 3)
  return `${depatureFrom} > AMS`
}

function getFormattedPassenger({ givenName, surname }) {
  return `${givenName} / ${surname}`
}
