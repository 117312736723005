import { useTranslate } from '/machinery/I18n'

export function FormFieldError({ error }) {
  const { __ } = useTranslate()

  const errorMessages = {
    email: _ => __`field-validation-email`,
    whitespace: _ => __`field-validation-whitespace`,
    checked: _ => __`field-validation-checked`,
    required: _ => __`field-validation-required`,
    atsPhoneNumber: _ => __`field-validation-atsPhoneNumber`,
    internalEmail: _ => __`field-validation-internalEmail`,
    internalEmailRefer: _ => __`field-validation-internalEmailRefer`,
    number: _ => __`field-validation-number`,
    min: x => __({ length: x })`field-validation-min`,
    max: x => __({ length: x })`field-validation-max`,
    maxLength: _ => __`field-validation-maxlength`,
    minLength: x => __({ length: x })`field-validation-minLength`,
    maxFileSize: x => __({ fileSize: bytesToMb(x) })`field-validation-max-fileSize`,
    minFileSize: x => __`field-validation-min-fileSize`,
    fileExtension: extensions => {
      return __({ fileExtensions: readable(extensions) })`field-validation-fileExtension`

      function readable(array) {
        const [last, ...rest] = array.slice().reverse()
        return `${rest.reverse().join(', ')} or ${last}`
      }
    },
  }

  return errorMessages[error.id](...error.params)
}

function bytesToMb(bytes) {
  return bytes / (1000 * 1000)
}
