import { useTargetAndRel } from '/machinery/useTargetAndRel'

import styles from './Link.css'

export function LinkPrimary({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <LinkBase className={cx(styles.componentPrimary, layoutClassName)} {...{ href, dataX, label, target }} />
}

function LinkBase({ href, dataX, label, target: targetOverride, className }) {
  const { target, rel } = useTargetAndRel({ targetOverride, href })

  return (
    <a data-x={dataX} className={cx(styles.componentBase, className)} {...{ href, rel, target }}>
      {label}
    </a>
  )
}
